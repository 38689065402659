/* toolbar */
.tox.tox-tinymce .tox-editor-container .tox-editor-header {
  background-color: #f8f8f8;
}

.tox.tox-tinymce .tox-editor-container .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
  background-color: #f8f8f8;
}

/* document padding */
.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap {
  background-color: #cecccc;
}

.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap iframe {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  background-color: white;
}

/* Left-aligned group */
.tox .tox-toolbar__group[title='left-group-buttons'] {
  margin-right: auto;
}

/* Right-aligned group */
.tox .tox-toolbar__group[title='right-group-buttons'] {
  column-gap: 4px;
  margin-left: auto;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button {
  font-size: 0.8125rem;
  font-weight: 600;
  color: white;

  padding: 0.75rem 0.438rem;
  height: 2rem;
  border: none;
  border-width: 1px;
  border-radius: 0.75rem;
  background-color: #1e3a8a;

  transition: background-color 0.3s;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:hover {
  background-color: #1e40af;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tox .tox-toolbar__group[title='right-group-buttons'] button .tox-tbtn__select-label {
  font-size: 0.8125rem;
  font-weight: 600;
  color: white;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:hover .tox-tbtn__select-label {
  cursor: pointer;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:disabled {
  background-color: #f3f4f6;
  color: #9ca3af;
  cursor: not-allowed;
}

/* .tox .tox-toolbar__group[title='right-group-buttons'] button .tox-icon.tox-tbtn__icon-wrap svg path {
  stroke: white;
  fill: transparent;
} */

/* .tox :not(svg):not(rect) */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:not(.tox *) {
  font-family: var(--inter-font) !important;
}

.w-inherit {
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #1e40af;
}

input[type='radio']:checked {
  border-color: #1e40af;
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.transition-width {
  transition: width 0.3s ease-in-out;
}

/*
  * setting width of ck-editor as 100% since it's a common component and
  * width should be handled by wrapper div on top of this
*/
.ck.ck.ck-editor {
  width: 100%;
}

.ck.ck-editor__editable_inline {
  min-height: 150px !important;
  max-height: 300px;
  overflow-y: scroll;
}

.ck.ck-editor__editable_inline ul {
  list-style-position: inside;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  font-size: 0.8125rem;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #475569 !important;
}

/* Doc Editor css changes for CK Editor */
.doc-editor .tox.tox-tinymce .tox-edit-area {
  overflow-y: scroll;
  max-height: calc(100vh - 20.45rem) !important;
}

.doc-editor.doc-editor-modal .tox.tox-tinymce .tox-edit-area {
  height: calc(100vh - 20.45rem) !important;
}

.doc-editor .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap {
  background: transparent;
}

.doc-editor .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  margin: none;
}

.doc-editor .tox.tox-tinymce.tox-tinymce--disabled .tox-sidebar-wrap {
  background: gray;
}

.doc-editor .tox.tox-tinymce.tox-tinymce--disabled .tox-sidebar-wrap .tox-edit-area {
  background: gray;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-border-color {
  border-color: #cbd5e1;
}

/* Custom styles */
@import url('./fonts.css');
@import url('./animations.css');
@import url('./tinyMCE/index.css');

#sentry-feedback {
  --background: #ffffff;
  --border: 1px solid #1e3a8a;
  --foreground: #1e3a8a;
  --submit-background: #1e3a8a;
  --submit-background-hover: #1e40af;
  --input-outline-focus: none;
  .widget__actor {
    svg {
      color: #1e3a8a;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Sofia:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Arizonia';
  src: local('AlexBrush'), url(webpack:///mini-css-extract-plugin//_next/static/media/Arizonia-Regular.e4073c40.ttf) format('truetype');
}

.font-arizonia {
  font-family: Arizonia, cursive;
}

.font-poppins {
  font-family: Poppins, cursive;
}

.font-sofia {
  font-family: Sofia, cursive;
}

.animate-breathe {
  animation: breathe 4s linear infinite;
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-coin {
  animation: coin 2s linear infinite;
}

@keyframes coin {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animate-flip {
  animation: flip 2s linear infinite;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(360deg);
  }
}

/* toolbar */
.tox.tox-tinymce .tox-editor-container .tox-editor-header {
  background-color: #f8f8f8;
}

.tox.tox-tinymce .tox-editor-container .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
  background-color: #f8f8f8;
}

/* document padding */
.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap {
  background-color: #cecccc;
}

.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap iframe {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  background-color: white;
}

/* Left-aligned group */
.tox .tox-toolbar__group[title='left-group-buttons'] {
  margin-right: auto;
}

/* Right-aligned group */
.tox .tox-toolbar__group[title='right-group-buttons'] {
  -moz-column-gap: 4px;
       column-gap: 4px;
  margin-left: auto;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button {
  font-size: 0.8125rem;
  font-weight: 600;
  color: white;

  padding: 0.75rem 0.438rem;
  height: 2rem;
  border: none;
  border-width: 1px;
  border-radius: 0.75rem;
  background-color: #1e3a8a;

  transition: background-color 0.3s;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:hover {
  background-color: #1e40af;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tox .tox-toolbar__group[title='right-group-buttons'] button .tox-tbtn__select-label {
  font-size: 0.8125rem;
  font-weight: 600;
  color: white;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:hover .tox-tbtn__select-label {
  cursor: pointer;
}

.tox .tox-toolbar__group[title='right-group-buttons'] button:disabled {
  background-color: #f3f4f6;
  color: #9ca3af;
  cursor: not-allowed;
}

/* .tox .tox-toolbar__group[title='right-group-buttons'] button .tox-icon.tox-tbtn__icon-wrap svg path {
  stroke: white;
  fill: transparent;
} */

/* .tox :not(svg):not(rect) */

*, ::before, ::after {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
::backdrop {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
.container {
      width: 100%;
}
@media (min-width: 640px) {
      .container {
            max-width: 640px;
      }
}
@media (min-width: 768px) {
      .container {
            max-width: 768px;
      }
}
@media (min-width: 1024px) {
      .container {
            max-width: 1024px;
      }
}
@media (min-width: 1280px) {
      .container {
            max-width: 1280px;
      }
}
@media (min-width: 1536px) {
      .container {
            max-width: 1536px;
      }
}
.sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
}
.pointer-events-none {
      pointer-events: none;
}
.pointer-events-auto {
      pointer-events: auto;
}
.visible {
      visibility: visible;
}
.invisible {
      visibility: hidden;
}
.collapse {
      visibility: collapse;
}
.static {
      position: static;
}
.fixed {
      position: fixed;
}
.absolute {
      position: absolute;
}
.relative {
      position: relative;
}
.sticky {
      position: sticky;
}
.inset-0 {
      inset: 0px;
}
.bottom-0 {
      bottom: 0px;
}
.bottom-10 {
      bottom: 2.5rem;
}
.bottom-\[-0\.75rem\] {
      bottom: -0.75rem;
}
.bottom-\[-9px\] {
      bottom: -9px;
}
.left-0 {
      left: 0px;
}
.left-1 {
      left: 0.25rem;
}
.left-1\/2 {
      left: 50%;
}
.right-0 {
      right: 0px;
}
.right-1 {
      right: 0.25rem;
}
.right-\[-12px\] {
      right: -12px;
}
.right-\[-1rem\] {
      right: -1rem;
}
.top-0 {
      top: 0px;
}
.top-1 {
      top: 0.25rem;
}
.top-1\.5 {
      top: 0.375rem;
}
.top-1\/2 {
      top: 50%;
}
.top-10 {
      top: 2.5rem;
}
.top-2 {
      top: 0.5rem;
}
.top-\[-12px\] {
      top: -12px;
}
.top-\[-3px\] {
      top: -3px;
}
.top-\[-9px\] {
      top: -9px;
}
.top-\[2\.875rem\] {
      top: 2.875rem;
}
.-z-10 {
      z-index: -10;
}
.z-0 {
      z-index: 0;
}
.z-10 {
      z-index: 10;
}
.z-20 {
      z-index: 20;
}
.z-30 {
      z-index: 30;
}
.z-50 {
      z-index: 50;
}
.z-\[11\] {
      z-index: 11;
}
.order-1 {
      order: 1;
}
.order-2 {
      order: 2;
}
.m-3 {
      margin: 0.75rem;
}
.m-auto {
      margin: auto;
}
.mx-0 {
      margin-left: 0px;
      margin-right: 0px;
}
.mx-1 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
}
.mx-3 {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
}
.mx-4 {
      margin-left: 1rem;
      margin-right: 1rem;
}
.mx-auto {
      margin-left: auto;
      margin-right: auto;
}
.my-1 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
}
.my-2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
}
.my-3 {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
}
.my-4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
}
.my-6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
}
.my-8 {
      margin-top: 2rem;
      margin-bottom: 2rem;
}
.my-auto {
      margin-top: auto;
      margin-bottom: auto;
}
.\!mt-10 {
      margin-top: 2.5rem !important;
}
.-mb-1 {
      margin-bottom: -0.25rem;
}
.-mb-2 {
      margin-bottom: -0.5rem;
}
.-me-1 {
      margin-inline-end: -0.25rem;
}
.mb-1 {
      margin-bottom: 0.25rem;
}
.mb-10 {
      margin-bottom: 2.5rem;
}
.mb-14 {
      margin-bottom: 3.5rem;
}
.mb-16 {
      margin-bottom: 4rem;
}
.mb-2 {
      margin-bottom: 0.5rem;
}
.mb-3 {
      margin-bottom: 0.75rem;
}
.mb-4 {
      margin-bottom: 1rem;
}
.mb-5 {
      margin-bottom: 1.25rem;
}
.mb-6 {
      margin-bottom: 1.5rem;
}
.mb-7 {
      margin-bottom: 1.75rem;
}
.mb-8 {
      margin-bottom: 2rem;
}
.mb-\[0\.125rem\] {
      margin-bottom: 0.125rem;
}
.mb-\[0\.5rem\] {
      margin-bottom: 0.5rem;
}
.mb-\[30px\] {
      margin-bottom: 30px;
}
.me-1 {
      margin-inline-end: 0.25rem;
}
.me-2 {
      margin-inline-end: 0.5rem;
}
.me-\[-6px\] {
      margin-inline-end: -6px;
}
.ml-1 {
      margin-left: 0.25rem;
}
.ml-2 {
      margin-left: 0.5rem;
}
.ml-3 {
      margin-left: 0.75rem;
}
.ml-4 {
      margin-left: 1rem;
}
.ml-7 {
      margin-left: 1.75rem;
}
.ml-8 {
      margin-left: 2rem;
}
.ml-\[0\.678rem\] {
      margin-left: 0.678rem;
}
.ml-auto {
      margin-left: auto;
}
.mr-1 {
      margin-right: 0.25rem;
}
.mr-2 {
      margin-right: 0.5rem;
}
.mr-4 {
      margin-right: 1rem;
}
.mr-5 {
      margin-right: 1.25rem;
}
.mr-6 {
      margin-right: 1.5rem;
}
.mr-auto {
      margin-right: auto;
}
.ms-1 {
      margin-inline-start: 0.25rem;
}
.ms-2 {
      margin-inline-start: 0.5rem;
}
.ms-3 {
      margin-inline-start: 0.75rem;
}
.ms-4 {
      margin-inline-start: 1rem;
}
.ms-\[-6px\] {
      margin-inline-start: -6px;
}
.ms-auto {
      margin-inline-start: auto;
}
.mt-0 {
      margin-top: 0px;
}
.mt-0\.5 {
      margin-top: 0.125rem;
}
.mt-1 {
      margin-top: 0.25rem;
}
.mt-10 {
      margin-top: 2.5rem;
}
.mt-12 {
      margin-top: 3rem;
}
.mt-14 {
      margin-top: 3.5rem;
}
.mt-2 {
      margin-top: 0.5rem;
}
.mt-2\.5 {
      margin-top: 0.625rem;
}
.mt-20 {
      margin-top: 5rem;
}
.mt-3 {
      margin-top: 0.75rem;
}
.mt-4 {
      margin-top: 1rem;
}
.mt-5 {
      margin-top: 1.25rem;
}
.mt-6 {
      margin-top: 1.5rem;
}
.mt-8 {
      margin-top: 2rem;
}
.mt-9 {
      margin-top: 2.25rem;
}
.mt-\[-1rem\] {
      margin-top: -1rem;
}
.mt-\[0\.125rem\] {
      margin-top: 0.125rem;
}
.mt-\[0\.72rem\] {
      margin-top: 0.72rem;
}
.mt-\[2\.5rem\] {
      margin-top: 2.5rem;
}
.mt-\[30px\] {
      margin-top: 30px;
}
.mt-\[5\.47rem\] {
      margin-top: 5.47rem;
}
.mt-auto {
      margin-top: auto;
}
.block {
      display: block;
}
.inline-block {
      display: inline-block;
}
.flex {
      display: flex;
}
.inline-flex {
      display: inline-flex;
}
.table {
      display: table;
}
.grid {
      display: grid;
}
.hidden {
      display: none;
}
.aspect-video {
      aspect-ratio: 16 / 9;
}
.\!h-\[2\.375rem\] {
      height: 2.375rem !important;
}
.h-1 {
      height: 0.25rem;
}
.h-10 {
      height: 2.5rem;
}
.h-11 {
      height: 2.75rem;
}
.h-12 {
      height: 3rem;
}
.h-16 {
      height: 4rem;
}
.h-20 {
      height: 5rem;
}
.h-3 {
      height: 0.75rem;
}
.h-36 {
      height: 9rem;
}
.h-4 {
      height: 1rem;
}
.h-40 {
      height: 10rem;
}
.h-5 {
      height: 1.25rem;
}
.h-52 {
      height: 13rem;
}
.h-6 {
      height: 1.5rem;
}
.h-7 {
      height: 1.75rem;
}
.h-8 {
      height: 2rem;
}
.h-9 {
      height: 2.25rem;
}
.h-96 {
      height: 24rem;
}
.h-\[0\.9375rem\] {
      height: 0.9375rem;
}
.h-\[0\.984rem\] {
      height: 0.984rem;
}
.h-\[1\.125rem\] {
      height: 1.125rem;
}
.h-\[1\.2rem\] {
      height: 1.2rem;
}
.h-\[1\.375rem\] {
      height: 1.375rem;
}
.h-\[1\.5rem\] {
      height: 1.5rem;
}
.h-\[1\.75rem\] {
      height: 1.75rem;
}
.h-\[1\.875rem\] {
      height: 1.875rem;
}
.h-\[12vh\] {
      height: 12vh;
}
.h-\[14rem\] {
      height: 14rem;
}
.h-\[155px\] {
      height: 155px;
}
.h-\[15rem\] {
      height: 15rem;
}
.h-\[168px\] {
      height: 168px;
}
.h-\[16rem\] {
      height: 16rem;
}
.h-\[17\.8634rem\] {
      height: 17.8634rem;
}
.h-\[18px\] {
      height: 18px;
}
.h-\[19\.2rem\] {
      height: 19.2rem;
}
.h-\[19rem\] {
      height: 19rem;
}
.h-\[2\.0625rem\] {
      height: 2.0625rem;
}
.h-\[2\.25rem\] {
      height: 2.25rem;
}
.h-\[2\.375rem\] {
      height: 2.375rem;
}
.h-\[2\.5rem\] {
      height: 2.5rem;
}
.h-\[2\.625rem\] {
      height: 2.625rem;
}
.h-\[2\.9906rem\] {
      height: 2.9906rem;
}
.h-\[2\.99rem\] {
      height: 2.99rem;
}
.h-\[20rem\] {
      height: 20rem;
}
.h-\[20vh\] {
      height: 20vh;
}
.h-\[22\.5rem\] {
      height: 22.5rem;
}
.h-\[22\.688rem\] {
      height: 22.688rem;
}
.h-\[22rem\] {
      height: 22rem;
}
.h-\[23\.5px\] {
      height: 23.5px;
}
.h-\[23rem\] {
      height: 23rem;
}
.h-\[24\.875rem\] {
      height: 24.875rem;
}
.h-\[26px\] {
      height: 26px;
}
.h-\[27\.765rem\] {
      height: 27.765rem;
}
.h-\[36\.25vh\] {
      height: 36.25vh;
}
.h-\[37\.5rem\] {
      height: 37.5rem;
}
.h-\[38rem\] {
      height: 38rem;
}
.h-\[3px\] {
      height: 3px;
}
.h-\[4\.5625rem\] {
      height: 4.5625rem;
}
.h-\[4\.5rem\] {
      height: 4.5rem;
}
.h-\[40vh\] {
      height: 40vh;
}
.h-\[41\.5vh\] {
      height: 41.5vh;
}
.h-\[42\.5vh\] {
      height: 42.5vh;
}
.h-\[42vh\] {
      height: 42vh;
}
.h-\[4rem\] {
      height: 4rem;
}
.h-\[50\%\] {
      height: 50%;
}
.h-\[50vh\] {
      height: 50vh;
}
.h-\[52vh\] {
      height: 52vh;
}
.h-\[53vh\] {
      height: 53vh;
}
.h-\[5rem\] {
      height: 5rem;
}
.h-\[6\.688rem\] {
      height: 6.688rem;
}
.h-\[60vh\] {
      height: 60vh;
}
.h-\[65\%\] {
      height: 65%;
}
.h-\[70vh\] {
      height: 70vh;
}
.h-\[75vh\] {
      height: 75vh;
}
.h-\[78vh\] {
      height: 78vh;
}
.h-\[7rem\] {
      height: 7rem;
}
.h-\[8\.063rem\] {
      height: 8.063rem;
}
.h-\[80vh\] {
      height: 80vh;
}
.h-\[85\%\] {
      height: 85%;
}
.h-\[96vh\] {
      height: 96vh;
}
.h-\[98vh\] {
      height: 98vh;
}
.h-\[calc\(100vh-11\.25rem\)\] {
      height: calc(100vh - 11.25rem);
}
.h-\[calc\(100vh-12\.375rem\)\] {
      height: calc(100vh - 12.375rem);
}
.h-\[calc\(100vh-12rem\)\] {
      height: calc(100vh - 12rem);
}
.h-\[calc\(100vh-17\.125rem\)\] {
      height: calc(100vh - 17.125rem);
}
.h-\[calc\(100vh-18\.25rem\)\] {
      height: calc(100vh - 18.25rem);
}
.h-\[calc\(100vh-4rem\)\] {
      height: calc(100vh - 4rem);
}
.h-\[calc\(100vh-9rem\)\] {
      height: calc(100vh - 9rem);
}
.h-auto {
      height: auto;
}
.h-full {
      height: 100%;
}
.h-screen {
      height: 100vh;
}
.max-h-\[0px\] {
      max-height: 0px;
}
.max-h-\[100rem\] {
      max-height: 100rem;
}
.max-h-\[11\.875rem\] {
      max-height: 11.875rem;
}
.max-h-\[16\.1rem\] {
      max-height: 16.1rem;
}
.max-h-\[16rem\] {
      max-height: 16rem;
}
.max-h-\[19\.5rem\] {
      max-height: 19.5rem;
}
.max-h-\[20\.875rem\] {
      max-height: 20.875rem;
}
.max-h-\[20rem\] {
      max-height: 20rem;
}
.max-h-\[22rem\] {
      max-height: 22rem;
}
.max-h-\[30rem\] {
      max-height: 30rem;
}
.max-h-\[35rem\] {
      max-height: 35rem;
}
.max-h-\[36\.765rem\] {
      max-height: 36.765rem;
}
.max-h-\[39\.75rem\] {
      max-height: 39.75rem;
}
.max-h-\[40rem\] {
      max-height: 40rem;
}
.max-h-\[50\.5rem\] {
      max-height: 50.5rem;
}
.max-h-\[53vh\] {
      max-height: 53vh;
}
.max-h-\[62\.5rem\] {
      max-height: 62.5rem;
}
.max-h-\[70vh\] {
      max-height: 70vh;
}
.max-h-\[85vh\] {
      max-height: 85vh;
}
.max-h-\[90vh\] {
      max-height: 90vh;
}
.max-h-full {
      max-height: 100%;
}
.max-h-screen {
      max-height: 100vh;
}
.min-h-0 {
      min-height: 0px;
}
.min-h-\[1\.25rem\] {
      min-height: 1.25rem;
}
.min-h-\[10vh\] {
      min-height: 10vh;
}
.min-h-\[11\.4rem\] {
      min-height: 11.4rem;
}
.min-h-\[15rem\] {
      min-height: 15rem;
}
.min-h-\[18rem\] {
      min-height: 18rem;
}
.min-h-\[19\.745rem\] {
      min-height: 19.745rem;
}
.min-h-\[20rem\] {
      min-height: 20rem;
}
.min-h-\[22rem\] {
      min-height: 22rem;
}
.min-h-\[3\.5rem\] {
      min-height: 3.5rem;
}
.min-h-\[376px\] {
      min-height: 376px;
}
.min-h-\[3rem\] {
      min-height: 3rem;
}
.min-h-\[76px\] {
      min-height: 76px;
}
.min-h-\[calc\(100vh-16rem\)\] {
      min-height: calc(100vh - 16rem);
}
.min-h-full {
      min-height: 100%;
}
.min-h-screen {
      min-height: 100vh;
}
.\!w-52 {
      width: 13rem !important;
}
.w-0 {
      width: 0px;
}
.w-1 {
      width: 0.25rem;
}
.w-1\/2 {
      width: 50%;
}
.w-1\/4 {
      width: 25%;
}
.w-10 {
      width: 2.5rem;
}
.w-11 {
      width: 2.75rem;
}
.w-16 {
      width: 4rem;
}
.w-2\/3 {
      width: 66.666667%;
}
.w-20 {
      width: 5rem;
}
.w-28 {
      width: 7rem;
}
.w-3 {
      width: 0.75rem;
}
.w-36 {
      width: 9rem;
}
.w-4 {
      width: 1rem;
}
.w-48 {
      width: 12rem;
}
.w-5 {
      width: 1.25rem;
}
.w-56 {
      width: 14rem;
}
.w-6 {
      width: 1.5rem;
}
.w-64 {
      width: 16rem;
}
.w-8 {
      width: 2rem;
}
.w-80 {
      width: 20rem;
}
.w-9 {
      width: 2.25rem;
}
.w-96 {
      width: 24rem;
}
.w-\[0\.9375rem\] {
      width: 0.9375rem;
}
.w-\[1\.125rem\] {
      width: 1.125rem;
}
.w-\[1\.2rem\] {
      width: 1.2rem;
}
.w-\[1\.375rem\] {
      width: 1.375rem;
}
.w-\[1\.5rem\] {
      width: 1.5rem;
}
.w-\[10\.8125rem\] {
      width: 10.8125rem;
}
.w-\[1000px\] {
      width: 1000px;
}
.w-\[10rem\] {
      width: 10rem;
}
.w-\[11\.25rem\] {
      width: 11.25rem;
}
.w-\[11\.5rem\] {
      width: 11.5rem;
}
.w-\[11\.75rem\] {
      width: 11.75rem;
}
.w-\[11rem\] {
      width: 11rem;
}
.w-\[12\.5rem\] {
      width: 12.5rem;
}
.w-\[12rem\] {
      width: 12rem;
}
.w-\[13\.75rem\] {
      width: 13.75rem;
}
.w-\[145px\] {
      width: 145px;
}
.w-\[16\.4375rem\] {
      width: 16.4375rem;
}
.w-\[16rem\] {
      width: 16rem;
}
.w-\[18px\] {
      width: 18px;
}
.w-\[2\.25rem\] {
      width: 2.25rem;
}
.w-\[2\.5rem\] {
      width: 2.5rem;
}
.w-\[2\.625rem\] {
      width: 2.625rem;
}
.w-\[2\.9906rem\] {
      width: 2.9906rem;
}
.w-\[20\.5rem\] {
      width: 20.5rem;
}
.w-\[215\.33px\] {
      width: 215.33px;
}
.w-\[22rem\] {
      width: 22rem;
}
.w-\[264px\] {
      width: 264px;
}
.w-\[27\.25vw\] {
      width: 27.25vw;
}
.w-\[28rem\] {
      width: 28rem;
}
.w-\[2rem\] {
      width: 2rem;
}
.w-\[31rem\] {
      width: 31rem;
}
.w-\[33\.89rem\] {
      width: 33.89rem;
}
.w-\[33rem\] {
      width: 33rem;
}
.w-\[36rem\] {
      width: 36rem;
}
.w-\[37\.5rem\] {
      width: 37.5rem;
}
.w-\[37\.875rem\] {
      width: 37.875rem;
}
.w-\[38\.875rem\] {
      width: 38.875rem;
}
.w-\[384px\] {
      width: 384px;
}
.w-\[4\.5625rem\] {
      width: 4.5625rem;
}
.w-\[4\.5rem\] {
      width: 4.5rem;
}
.w-\[40rem\] {
      width: 40rem;
}
.w-\[46\.25rem\] {
      width: 46.25rem;
}
.w-\[46\.875rem\] {
      width: 46.875rem;
}
.w-\[47rem\] {
      width: 47rem;
}
.w-\[4rem\] {
      width: 4rem;
}
.w-\[5\.1875rem\] {
      width: 5.1875rem;
}
.w-\[5\.5rem\] {
      width: 5.5rem;
}
.w-\[50\%\] {
      width: 50%;
}
.w-\[50rem\] {
      width: 50rem;
}
.w-\[52\.5rem\] {
      width: 52.5rem;
}
.w-\[526px\] {
      width: 526px;
}
.w-\[53\.3125rem\] {
      width: 53.3125rem;
}
.w-\[55rem\] {
      width: 55rem;
}
.w-\[56\.875rem\] {
      width: 56.875rem;
}
.w-\[56rem\] {
      width: 56rem;
}
.w-\[6\.2rem\] {
      width: 6.2rem;
}
.w-\[62\.5rem\] {
      width: 62.5rem;
}
.w-\[64\.875rem\] {
      width: 64.875rem;
}
.w-\[65\.625rem\] {
      width: 65.625rem;
}
.w-\[65rem\] {
      width: 65rem;
}
.w-\[65vw\] {
      width: 65vw;
}
.w-\[66\.875rem\] {
      width: 66.875rem;
}
.w-\[67rem\] {
      width: 67rem;
}
.w-\[7\.5rem\] {
      width: 7.5rem;
}
.w-\[7\.9375rem\] {
      width: 7.9375rem;
}
.w-\[73\.125rem\] {
      width: 73.125rem;
}
.w-\[7rem\] {
      width: 7rem;
}
.w-\[8\.34rem\] {
      width: 8.34rem;
}
.w-\[8\.75rem\] {
      width: 8.75rem;
}
.w-\[80\%\] {
      width: 80%;
}
.w-\[80px\] {
      width: 80px;
}
.w-\[8rem\] {
      width: 8rem;
}
.w-\[9\.2rem\] {
      width: 9.2rem;
}
.w-\[97vw\] {
      width: 97vw;
}
.w-\[calc\(100\%-13\.75rem\)\] {
      width: calc(100% - 13.75rem);
}
.w-\[calc\(100\%-4\.5rem\)\] {
      width: calc(100% - 4.5rem);
}
.w-auto {
      width: auto;
}
.w-fit {
      width: -moz-fit-content;
      width: fit-content;
}
.w-full {
      width: 100%;
}
.w-max {
      width: -moz-max-content;
      width: max-content;
}
.min-w-\[12\.5rem\] {
      min-width: 12.5rem;
}
.min-w-\[17\.8634rem\] {
      min-width: 17.8634rem;
}
.min-w-\[3\.5rem\] {
      min-width: 3.5rem;
}
.min-w-\[300px\] {
      min-width: 300px;
}
.min-w-\[32\.8rem\] {
      min-width: 32.8rem;
}
.min-w-\[450px\] {
      min-width: 450px;
}
.min-w-\[46\.25rem\] {
      min-width: 46.25rem;
}
.min-w-\[50rem\] {
      min-width: 50rem;
}
.min-w-\[56\.875rem\] {
      min-width: 56.875rem;
}
.max-w-\[10\.5625rem\] {
      max-width: 10.5625rem;
}
.max-w-\[100vw\] {
      max-width: 100vw;
}
.max-w-\[11\.75rem\] {
      max-width: 11.75rem;
}
.max-w-\[11rem\] {
      max-width: 11rem;
}
.max-w-\[12\.0625rem\] {
      max-width: 12.0625rem;
}
.max-w-\[12rem\] {
      max-width: 12rem;
}
.max-w-\[13\.125rem\] {
      max-width: 13.125rem;
}
.max-w-\[13\.1875rem\] {
      max-width: 13.1875rem;
}
.max-w-\[13rem\] {
      max-width: 13rem;
}
.max-w-\[14rem\] {
      max-width: 14rem;
}
.max-w-\[19\.275rem\] {
      max-width: 19.275rem;
}
.max-w-\[197px\] {
      max-width: 197px;
}
.max-w-\[20\.75rem\] {
      max-width: 20.75rem;
}
.max-w-\[23\.275rem\] {
      max-width: 23.275rem;
}
.max-w-\[246px\] {
      max-width: 246px;
}
.max-w-\[24rem\] {
      max-width: 24rem;
}
.max-w-\[25rem\] {
      max-width: 25rem;
}
.max-w-\[32\.8rem\] {
      max-width: 32.8rem;
}
.max-w-\[35rem\] {
      max-width: 35rem;
}
.max-w-\[42rem\] {
      max-width: 42rem;
}
.max-w-\[45vw\] {
      max-width: 45vw;
}
.max-w-\[60rem\] {
      max-width: 60rem;
}
.max-w-\[610px\] {
      max-width: 610px;
}
.max-w-\[62\.5rem\] {
      max-width: 62.5rem;
}
.max-w-\[64rem\] {
      max-width: 64rem;
}
.max-w-\[6rem\] {
      max-width: 6rem;
}
.max-w-\[90vw\] {
      max-width: 90vw;
}
.max-w-\[95vw\] {
      max-width: 95vw;
}
.max-w-lg {
      max-width: 32rem;
}
.max-w-sm {
      max-width: 24rem;
}
.max-w-xs {
      max-width: 20rem;
}
.flex-1 {
      flex: 1 1 0%;
}
.flex-\[2\] {
      flex: 2;
}
.flex-shrink-0 {
      flex-shrink: 0;
}
.shrink-0 {
      flex-shrink: 0;
}
.flex-grow {
      flex-grow: 1;
}
.border-collapse {
      border-collapse: collapse;
}
.origin-\[0\] {
      transform-origin: 0;
}
.-translate-x-1\/2 {
      --tw-translate-x: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-8 {
      --tw-translate-y: -2rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-2 {
      --tw-translate-x: 0.5rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-50\%\] {
      --tw-translate-x: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0 {
      --tw-rotate: 0deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
      --tw-rotate: 180deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[270deg\] {
      --tw-rotate: 270deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-75 {
      --tw-scale-x: .75;
      --tw-scale-y: .75;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
      --tw-scale-x: .95;
      --tw-scale-y: .95;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[2\] {
      --tw-scale-x: 2;
      --tw-scale-y: 2;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin {
      to {
            transform: rotate(360deg);
      }
}
.animate-spin {
      animation: spin 1s linear infinite;
}
.cursor-default {
      cursor: default;
}
.cursor-help {
      cursor: help;
}
.cursor-move {
      cursor: move;
}
.cursor-not-allowed {
      cursor: not-allowed;
}
.cursor-pointer {
      cursor: pointer;
}
.cursor-text {
      cursor: text;
}
.resize-none {
      resize: none;
}
.resize {
      resize: both;
}
.list-inside {
      list-style-position: inside;
}
.appearance-none {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
}
.grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
      grid-template-rows: repeat(4, minmax(0, 1fr));
}
.\!flex-row {
      flex-direction: row !important;
}
.flex-row {
      flex-direction: row;
}
.flex-row-reverse {
      flex-direction: row-reverse;
}
.flex-col {
      flex-direction: column;
}
.flex-col-reverse {
      flex-direction: column-reverse;
}
.flex-wrap {
      flex-wrap: wrap;
}
.items-start {
      align-items: flex-start;
}
.items-end {
      align-items: flex-end;
}
.items-center {
      align-items: center;
}
.items-baseline {
      align-items: baseline;
}
.items-stretch {
      align-items: stretch;
}
.justify-start {
      justify-content: flex-start;
}
.justify-end {
      justify-content: flex-end;
}
.justify-center {
      justify-content: center;
}
.justify-between {
      justify-content: space-between;
}
.justify-around {
      justify-content: space-around;
}
.justify-items-stretch {
      justify-items: stretch;
}
.gap-1 {
      gap: 0.25rem;
}
.gap-10 {
      gap: 2.5rem;
}
.gap-12 {
      gap: 3rem;
}
.gap-16 {
      gap: 4rem;
}
.gap-2 {
      gap: 0.5rem;
}
.gap-2\.5 {
      gap: 0.625rem;
}
.gap-24 {
      gap: 6rem;
}
.gap-3 {
      gap: 0.75rem;
}
.gap-32 {
      gap: 8rem;
}
.gap-4 {
      gap: 1rem;
}
.gap-48 {
      gap: 12rem;
}
.gap-5 {
      gap: 1.25rem;
}
.gap-6 {
      gap: 1.5rem;
}
.gap-8 {
      gap: 2rem;
}
.gap-9 {
      gap: 2.25rem;
}
.gap-\[0\.5rem\] {
      gap: 0.5rem;
}
.gap-\[0\.65rem\] {
      gap: 0.65rem;
}
.gap-\[0\.8438rem\] {
      gap: 0.8438rem;
}
.gap-\[0\.8rem\] {
      gap: 0.8rem;
}
.gap-\[1\.5rem\] {
      gap: 1.5rem;
}
.gap-\[4\.438rem\] {
      gap: 4.438rem;
}
.gap-x-16 {
      -moz-column-gap: 4rem;
           column-gap: 4rem;
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-x-reverse: 0;
      margin-right: calc(0.75rem * var(--tw-space-x-reverse));
      margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.self-start {
      align-self: flex-start;
}
.self-end {
      align-self: flex-end;
}
.self-center {
      align-self: center;
}
.overflow-auto {
      overflow: auto;
}
.overflow-hidden {
      overflow: hidden;
}
.overflow-visible {
      overflow: visible;
}
.overflow-x-auto {
      overflow-x: auto;
}
.overflow-y-auto {
      overflow-y: auto;
}
.overflow-y-hidden {
      overflow-y: hidden;
}
.overflow-y-scroll {
      overflow-y: scroll;
}
.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
.text-ellipsis {
      text-overflow: ellipsis;
}
.whitespace-normal {
      white-space: normal;
}
.whitespace-nowrap {
      white-space: nowrap;
}
.whitespace-pre-line {
      white-space: pre-line;
}
.rounded {
      border-radius: 0.25rem;
}
.rounded-2xl {
      border-radius: 1rem;
}
.rounded-\[0\.25rem\] {
      border-radius: 0.25rem;
}
.rounded-\[0\.375rem\] {
      border-radius: 0.375rem;
}
.rounded-\[0\.5rem\] {
      border-radius: 0.5rem;
}
.rounded-\[2\.5rem\] {
      border-radius: 2.5rem;
}
.rounded-full {
      border-radius: 9999px;
}
.rounded-lg {
      border-radius: 0.5rem;
}
.rounded-md {
      border-radius: 0.375rem;
}
.rounded-sidebar {
      border-radius: 0.75rem 0 0 0.75rem;
}
.rounded-xl {
      border-radius: 0.75rem;
}
.rounded-b-2xl {
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
}
.rounded-b-lg {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
}
.rounded-l-2xl {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
}
.rounded-r-2xl {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
}
.rounded-t-2xl {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
}
.rounded-bl-lg {
      border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg {
      border-bottom-right-radius: 0.5rem;
}
.border {
      border-width: 1px;
}
.border-0 {
      border-width: 0px;
}
.border-2 {
      border-width: 2px;
}
.border-\[0\.125rem\] {
      border-width: 0.125rem;
}
.border-\[0\.1px\] {
      border-width: 0.1px;
}
.border-\[0\.25rem\] {
      border-width: 0.25rem;
}
.border-y-\[0\.0625rem\] {
      border-top-width: 0.0625rem;
      border-bottom-width: 0.0625rem;
}
.border-b {
      border-bottom-width: 1px;
}
.border-b-0 {
      border-bottom-width: 0px;
}
.border-l-0 {
      border-left-width: 0px;
}
.border-r-0 {
      border-right-width: 0px;
}
.border-s {
      border-inline-start-width: 1px;
}
.border-t-0 {
      border-top-width: 0px;
}
.border-solid {
      border-style: solid;
}
.border-dashed {
      border-style: dashed;
}
.border-none {
      border-style: none;
}
.border-\[\#8a3fda\] {
      --tw-border-opacity: 1;
      border-color: rgb(138 63 218 / var(--tw-border-opacity));
}
.border-\[\#F0FDF5\] {
      --tw-border-opacity: 1;
      border-color: rgb(240 253 245 / var(--tw-border-opacity));
}
.border-blue-300 {
      --tw-border-opacity: 1;
      border-color: rgb(147 197 253 / var(--tw-border-opacity));
}
.border-blue-900 {
      --tw-border-opacity: 1;
      border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.border-blue100 {
      --tw-border-opacity: 1;
      border-color: rgb(219 234 254 / var(--tw-border-opacity));
}
.border-blue600 {
      --tw-border-opacity: 1;
      border-color: rgb(37 99 235 / var(--tw-border-opacity));
}
.border-blue900 {
      --tw-border-opacity: 1;
      border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.border-gray-200 {
      --tw-border-opacity: 1;
      border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-gray-300 {
      --tw-border-opacity: 1;
      border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-green-300 {
      --tw-border-opacity: 1;
      border-color: rgb(134 239 172 / var(--tw-border-opacity));
}
.border-green500 {
      --tw-border-opacity: 1;
      border-color: rgb(16 185 129 / var(--tw-border-opacity));
}
.border-grey100 {
      --tw-border-opacity: 1;
      border-color: rgb(217 217 217 / var(--tw-border-opacity));
}
.border-grey200 {
      --tw-border-opacity: 1;
      border-color: rgb(234 236 240 / var(--tw-border-opacity));
}
.border-grey400 {
      --tw-border-opacity: 1;
      border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.border-neutral-400 {
      --tw-border-opacity: 1;
      border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.border-neutral100 {
      --tw-border-opacity: 1;
      border-color: rgb(241 245 249 / var(--tw-border-opacity));
}
.border-neutral200 {
      --tw-border-opacity: 1;
      border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border-neutral300 {
      --tw-border-opacity: 1;
      border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.border-neutral400 {
      --tw-border-opacity: 1;
      border-color: rgb(148 163 184 / var(--tw-border-opacity));
}
.border-red-300 {
      --tw-border-opacity: 1;
      border-color: rgb(252 165 165 / var(--tw-border-opacity));
}
.border-red-500 {
      --tw-border-opacity: 1;
      border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-red-600 {
      --tw-border-opacity: 1;
      border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.border-red500 {
      --tw-border-opacity: 1;
      border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-red600 {
      --tw-border-opacity: 1;
      border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.border-slate-200 {
      --tw-border-opacity: 1;
      border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border-slate-300 {
      --tw-border-opacity: 1;
      border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.border-teal-400 {
      --tw-border-opacity: 1;
      border-color: rgb(45 212 191 / var(--tw-border-opacity));
}
.border-teal-600 {
      --tw-border-opacity: 1;
      border-color: rgb(13 148 136 / var(--tw-border-opacity));
}
.border-violet-600 {
      --tw-border-opacity: 1;
      border-color: rgb(124 58 237 / var(--tw-border-opacity));
}
.border-white {
      --tw-border-opacity: 1;
      border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-yellow-300 {
      --tw-border-opacity: 1;
      border-color: rgb(253 224 71 / var(--tw-border-opacity));
}
.border-x-transparent {
      border-left-color: transparent;
      border-right-color: transparent;
}
.border-y-slate-300 {
      --tw-border-opacity: 1;
      border-top-color: rgb(203 213 225 / var(--tw-border-opacity));
      border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.\!bg-blue100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue900 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity)) !important;
}
.\!bg-grey200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(234 236 240 / var(--tw-bg-opacity)) !important;
}
.\!bg-teal-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(204 251 241 / var(--tw-bg-opacity)) !important;
}
.\!bg-violet-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(237 233 254 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#93C5FD\] {
      --tw-bg-opacity: 1;
      background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.bg-\[\#ECECF2\] {
      --tw-bg-opacity: 1;
      background-color: rgb(236 236 242 / var(--tw-bg-opacity));
}
.bg-\[\#EFF6FF\] {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-\[\#F0FDF5\] {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 245 / var(--tw-bg-opacity));
}
.bg-black {
      --tw-bg-opacity: 1;
      background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-blue-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.bg-blue-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.bg-blue-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-blue-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.bg-blue-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-blue100 {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.bg-blue50 {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-blue800 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.bg-blue900 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-gray-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bg-gray-300 {
      --tw-bg-opacity: 1;
      background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-gray-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bg-green-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.bg-green-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}
.bg-green50 {
      --tw-bg-opacity: 1;
      background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.bg-grey100 {
      --tw-bg-opacity: 1;
      background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}
.bg-grey300 {
      --tw-bg-opacity: 1;
      background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-inherit {
      background-color: inherit;
}
.bg-neutral-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-neutral-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.bg-neutral100 {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-neutral200 {
      --tw-bg-opacity: 1;
      background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.bg-neutral300 {
      --tw-bg-opacity: 1;
      background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.bg-neutral50 {
      --tw-bg-opacity: 1;
      background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.bg-orange-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}
.bg-orange-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}
.bg-orange-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}
.bg-red-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.bg-red-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}
.bg-red50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.bg-slate-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-slate-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.bg-teal-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.bg-transparent {
      background-color: transparent;
}
.bg-trinidad50 {
      --tw-bg-opacity: 1;
      background-color: rgb(255 243 237 / var(--tw-bg-opacity));
}
.bg-violet-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}
.bg-white {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.bg-opacity-25 {
      --tw-bg-opacity: 0.25;
}
.bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_0\%\2c _\#6a3fda_55\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\] {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 0%, #6a3fda 55%, rgba(30,58,138,1) 100%);
}
.bg-gradient-to-tr {
      background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.from-yellow-400 {
      --tw-gradient-from: #facc15 var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.via-yellow-200 {
      --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), #fef08a var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.to-yellow-600 {
      --tw-gradient-to: #ca8a04 var(--tw-gradient-to-position);
}
.bg-\[length\:100\%_150\%\] {
      background-size: 100% 150%;
}
.bg-cover {
      background-size: cover;
}
.bg-no-repeat {
      background-repeat: no-repeat;
}
.fill-\[\#0D9488\] {
      fill: #0D9488;
}
.fill-\[\#1E293B\] {
      fill: #1E293B;
}
.fill-\[\#1E3A8A\] {
      fill: #1E3A8A;
}
.fill-\[\#1E40AF\] {
      fill: #1E40AF;
}
.fill-\[\#1e40af\] {
      fill: #1e40af;
}
.fill-\[\#64748B\] {
      fill: #64748B;
}
.fill-\[\#955CF6\] {
      fill: #955CF6;
}
.fill-\[\#DBEAFE\] {
      fill: #DBEAFE;
}
.fill-\[\#EA580C\] {
      fill: #EA580C;
}
.fill-\[\#F4C430\] {
      fill: #F4C430;
}
.fill-\[\#F96416\] {
      fill: #F96416;
}
.fill-\[\#bdffff\] {
      fill: #bdffff;
}
.fill-\[\#ffffff\] {
      fill: #ffffff;
}
.fill-\[blue100\] {
      fill: blue100;
}
.fill-black {
      fill: #000;
}
.fill-blue-600 {
      fill: #2563eb;
}
.fill-blue-900 {
      fill: #1e3a8a;
}
.fill-blue100 {
      fill: #dbeafe;
}
.fill-blue800 {
      fill: #1e40af;
}
.fill-blue900 {
      fill: #1e3a8a;
}
.fill-gray-400 {
      fill: #9ca3af;
}
.fill-gray-500 {
      fill: #6b7280;
}
.fill-green-800 {
      fill: #166534;
}
.fill-neutral500 {
      fill: #64748b;
}
.fill-neutral600 {
      fill: #475569;
}
.fill-neutral800 {
      fill: #1e293b;
}
.fill-none {
      fill: none;
}
.fill-red-600 {
      fill: #dc2626;
}
.fill-red-800 {
      fill: #991b1b;
}
.fill-red600 {
      fill: #dc2626;
}
.fill-teal-700 {
      fill: #0f766e;
}
.fill-transparent {
      fill: transparent;
}
.fill-white {
      fill: #fff;
}
.stroke-\[\#0D9488\] {
      stroke: #0D9488;
}
.stroke-\[\#1E293B\] {
      stroke: #1E293B;
}
.stroke-\[\#1E3A8A\] {
      stroke: #1E3A8A;
}
.stroke-\[\#1E40AF\] {
      stroke: #1E40AF;
}
.stroke-\[\#64748B\] {
      stroke: #64748B;
}
.stroke-\[\#EA580C\] {
      stroke: #EA580C;
}
.stroke-\[\#F96416\] {
      stroke: #F96416;
}
.stroke-\[\#ffffff\] {
      stroke: #ffffff;
}
.stroke-\[blue100\] {
      stroke: blue100;
}
.stroke-black {
      stroke: #000;
}
.stroke-blue-800 {
      stroke: #1e40af;
}
.stroke-blue-900 {
      stroke: #1e3a8a;
}
.stroke-blue100 {
      stroke: #dbeafe;
}
.stroke-blue800 {
      stroke: #1e40af;
}
.stroke-blue900 {
      stroke: #1e3a8a;
}
.stroke-gray-400 {
      stroke: #9ca3af;
}
.stroke-grey500 {
      stroke: #6b7280;
}
.stroke-neutral600 {
      stroke: #475569;
}
.stroke-neutral800 {
      stroke: #1e293b;
}
.stroke-none {
      stroke: none;
}
.stroke-red600 {
      stroke: #dc2626;
}
.stroke-teal-600 {
      stroke: #0d9488;
}
.stroke-teal600 {
      stroke: #0d9488;
}
.stroke-transparent {
      stroke: transparent;
}
.stroke-violet-600 {
      stroke: #7c3aed;
}
.stroke-white {
      stroke: #fff;
}
.stroke-yellow-800 {
      stroke: #854d0e;
}
.stroke-\[2\.5px\] {
      stroke-width: 2.5px;
}
.object-contain {
      -o-object-fit: contain;
         object-fit: contain;
}
.object-fill {
      -o-object-fit: fill;
         object-fit: fill;
}
.\!p-0 {
      padding: 0px !important;
}
.p-0 {
      padding: 0px;
}
.p-1 {
      padding: 0.25rem;
}
.p-10 {
      padding: 2.5rem;
}
.p-12 {
      padding: 3rem;
}
.p-2 {
      padding: 0.5rem;
}
.p-2\.5 {
      padding: 0.625rem;
}
.p-3 {
      padding: 0.75rem;
}
.p-4 {
      padding: 1rem;
}
.p-5 {
      padding: 1.25rem;
}
.p-6 {
      padding: 1.5rem;
}
.p-7 {
      padding: 1.75rem;
}
.p-8 {
      padding: 2rem;
}
.p-9 {
      padding: 2.25rem;
}
.p-\[0\.25rem\] {
      padding: 0.25rem;
}
.p-\[0\.2rem\] {
      padding: 0.2rem;
}
.p-\[0rem\] {
      padding: 0rem;
}
.p-\[1\.5rem\] {
      padding: 1.5rem;
}
.p-\[2\.33rem\] {
      padding: 2.33rem;
}
.p-\[2\.5rem\] {
      padding: 2.5rem;
}
.p-\[2px\] {
      padding: 2px;
}
.p-\[calc\(100\%-18rem\)\] {
      padding: calc(100% - 18rem);
}
.\!px-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
}
.\!py-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
}
.px-0 {
      padding-left: 0px;
      padding-right: 0px;
}
.px-1 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
}
.px-10 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
}
.px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
}
.px-3 {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
}
.px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
}
.px-5 {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
}
.px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
}
.px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
}
.px-\[0\.438rem\] {
      padding-left: 0.438rem;
      padding-right: 0.438rem;
}
.px-\[0\.625rem\] {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
}
.px-\[0\.75rem\] {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
}
.px-\[0\] {
      padding-left: 0;
      padding-right: 0;
}
.px-\[0px\] {
      padding-left: 0px;
      padding-right: 0px;
}
.px-\[0rem\] {
      padding-left: 0rem;
      padding-right: 0rem;
}
.px-\[1\.625rem\] {
      padding-left: 1.625rem;
      padding-right: 1.625rem;
}
.px-\[3rem\] {
      padding-left: 3rem;
      padding-right: 3rem;
}
.py-0 {
      padding-top: 0px;
      padding-bottom: 0px;
}
.py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
}
.py-1\.5 {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
}
.py-11 {
      padding-top: 2.75rem;
      padding-bottom: 2.75rem;
}
.py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.py-3 {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
}
.py-4 {
      padding-top: 1rem;
      padding-bottom: 1rem;
}
.py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
}
.py-8 {
      padding-top: 2rem;
      padding-bottom: 2rem;
}
.py-\[0\.375rem\] {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
}
.py-\[0\.438rem\] {
      padding-top: 0.438rem;
      padding-bottom: 0.438rem;
}
.py-\[0\.5rem\] {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.py-\[0\.6563rem\] {
      padding-top: 0.6563rem;
      padding-bottom: 0.6563rem;
}
.py-\[0\.75rem\] {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
}
.py-\[0\.9375rem\] {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
}
.py-\[0\.938rem\] {
      padding-top: 0.938rem;
      padding-bottom: 0.938rem;
}
.py-\[0\] {
      padding-top: 0;
      padding-bottom: 0;
}
.py-\[1\.125rem\] {
      padding-top: 1.125rem;
      padding-bottom: 1.125rem;
}
.py-\[1rem\] {
      padding-top: 1rem;
      padding-bottom: 1rem;
}
.py-\[2\.5rem\] {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
}
.\!ps-6 {
      padding-inline-start: 1.5rem !important;
}
.pb-16 {
      padding-bottom: 4rem;
}
.pb-2 {
      padding-bottom: 0.5rem;
}
.pb-3 {
      padding-bottom: 0.75rem;
}
.pb-4 {
      padding-bottom: 1rem;
}
.pb-\[18px\] {
      padding-bottom: 18px;
}
.pe-0 {
      padding-inline-end: 0px;
}
.pe-4 {
      padding-inline-end: 1rem;
}
.pl-1 {
      padding-left: 0.25rem;
}
.pl-12 {
      padding-left: 3rem;
}
.pl-2 {
      padding-left: 0.5rem;
}
.pl-4 {
      padding-left: 1rem;
}
.pl-5 {
      padding-left: 1.25rem;
}
.pl-6 {
      padding-left: 1.5rem;
}
.pr-2 {
      padding-right: 0.5rem;
}
.pr-3 {
      padding-right: 0.75rem;
}
.pr-4 {
      padding-right: 1rem;
}
.pr-6 {
      padding-right: 1.5rem;
}
.ps-2 {
      padding-inline-start: 0.5rem;
}
.ps-4 {
      padding-inline-start: 1rem;
}
.pt-0 {
      padding-top: 0px;
}
.pt-2 {
      padding-top: 0.5rem;
}
.pt-6 {
      padding-top: 1.5rem;
}
.pt-\[0\.125rem\] {
      padding-top: 0.125rem;
}
.text-left {
      text-align: left;
}
.\!text-center {
      text-align: center !important;
}
.text-center {
      text-align: center;
}
.text-justify {
      text-align: justify;
}
.text-start {
      text-align: start;
}
.align-middle {
      vertical-align: middle;
}
.text-\[0\.75rem\] {
      font-size: 0.75rem;
}
.text-\[0\.7rem\] {
      font-size: 0.7rem;
}
.text-\[0\.8125rem\] {
      font-size: 0.8125rem;
}
.text-\[0\.875rem\] {
      font-size: 0.875rem;
}
.text-\[0\.8rem\] {
      font-size: 0.8rem;
}
.text-base {
      font-size: 1rem;
      line-height: 1.5rem;
}
.text-body {
      font-size: 0.875rem;
      line-height: 1.3125rem;
}
.text-callout {
      font-size: 0.8125rem;
      line-height: 1.1875rem;
}
.text-container-text {
      font-size: 0.75rem;
      line-height: 1.125rem;
}
.text-display1 {
      font-size: 2.125rem;
      line-height: 3.188rem;
}
.text-display2 {
      font-size: 1.875rem;
      line-height: 2.813rem;
}
.text-footnote {
      font-size: 0.6875rem;
      line-height: 1rem;
}
.text-heading1 {
      font-size: 1.125rem;
      line-height: 1.75rem;
}
.text-heading2 {
      font-size: 1rem;
      line-height: 1.5rem;
}
.text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
}
.text-title1 {
      font-size: 1.625rem;
      line-height: 2.438rem;
}
.text-title2 {
      font-size: 1.4375rem;
      line-height: 2.125rem;
}
.text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
}
.\!font-medium {
      font-weight: 500 !important;
}
.font-\[600\] {
      font-weight: 600;
}
.font-bold {
      font-weight: 700;
}
.font-medium {
      font-weight: 500;
}
.font-normal {
      font-weight: 400;
}
.font-semibold {
      font-weight: 600;
}
.uppercase {
      text-transform: uppercase;
}
.italic {
      font-style: italic;
}
.leading-4 {
      line-height: 1rem;
}
.leading-\[0\] {
      line-height: 0;
}
.leading-\[1\.1875rem\] {
      line-height: 1.1875rem;
}
.leading-\[1\.315rem\] {
      line-height: 1.315rem;
}
.leading-\[17px\] {
      line-height: 17px;
}
.\!text-orange-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(234 88 12 / var(--tw-text-opacity)) !important;
}
.\!text-white {
      --tw-text-opacity: 1 !important;
      color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#0D9488\] {
      --tw-text-opacity: 1;
      color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-black {
      --tw-text-opacity: 1;
      color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-blue-100 {
      --tw-text-opacity: 1;
      color: rgb(219 234 254 / var(--tw-text-opacity));
}
.text-blue-800 {
      --tw-text-opacity: 1;
      color: rgb(30 64 175 / var(--tw-text-opacity));
}
.text-blue-900 {
      --tw-text-opacity: 1;
      color: rgb(30 58 138 / var(--tw-text-opacity));
}
.text-blue100 {
      --tw-text-opacity: 1;
      color: rgb(219 234 254 / var(--tw-text-opacity));
}
.text-blue600 {
      --tw-text-opacity: 1;
      color: rgb(37 99 235 / var(--tw-text-opacity));
}
.text-blue800 {
      --tw-text-opacity: 1;
      color: rgb(30 64 175 / var(--tw-text-opacity));
}
.text-blue900 {
      --tw-text-opacity: 1;
      color: rgb(30 58 138 / var(--tw-text-opacity));
}
.text-gray-200 {
      --tw-text-opacity: 1;
      color: rgb(229 231 235 / var(--tw-text-opacity));
}
.text-gray-400 {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-600 {
      --tw-text-opacity: 1;
      color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-gray-900 {
      --tw-text-opacity: 1;
      color: rgb(17 24 39 / var(--tw-text-opacity));
}
.text-green-800 {
      --tw-text-opacity: 1;
      color: rgb(22 101 52 / var(--tw-text-opacity));
}
.text-green500 {
      --tw-text-opacity: 1;
      color: rgb(16 185 129 / var(--tw-text-opacity));
}
.text-grey400 {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-grey500 {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-lightBlack {
      --tw-text-opacity: 1;
      color: rgb(51 51 51 / var(--tw-text-opacity));
}
.text-neutral-300 {
      --tw-text-opacity: 1;
      color: rgb(212 212 212 / var(--tw-text-opacity));
}
.text-neutral-400 {
      --tw-text-opacity: 1;
      color: rgb(163 163 163 / var(--tw-text-opacity));
}
.text-neutral-600 {
      --tw-text-opacity: 1;
      color: rgb(82 82 82 / var(--tw-text-opacity));
}
.text-neutral-800 {
      --tw-text-opacity: 1;
      color: rgb(38 38 38 / var(--tw-text-opacity));
}
.text-neutral-900 {
      --tw-text-opacity: 1;
      color: rgb(23 23 23 / var(--tw-text-opacity));
}
.text-neutral400 {
      --tw-text-opacity: 1;
      color: rgb(148 163 184 / var(--tw-text-opacity));
}
.text-neutral500 {
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-neutral600 {
      --tw-text-opacity: 1;
      color: rgb(71 85 105 / var(--tw-text-opacity));
}
.text-neutral800 {
      --tw-text-opacity: 1;
      color: rgb(30 41 59 / var(--tw-text-opacity));
}
.text-orange-500 {
      --tw-text-opacity: 1;
      color: rgb(249 115 22 / var(--tw-text-opacity));
}
.text-orange-600 {
      --tw-text-opacity: 1;
      color: rgb(234 88 12 / var(--tw-text-opacity));
}
.text-orange600 {
      --tw-text-opacity: 1;
      color: rgb(234 88 12 / var(--tw-text-opacity));
}
.text-red-400 {
      --tw-text-opacity: 1;
      color: rgb(248 113 113 / var(--tw-text-opacity));
}
.text-red-500 {
      --tw-text-opacity: 1;
      color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-600 {
      --tw-text-opacity: 1;
      color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-red-800 {
      --tw-text-opacity: 1;
      color: rgb(153 27 27 / var(--tw-text-opacity));
}
.text-red500 {
      --tw-text-opacity: 1;
      color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-slate-500 {
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-slate-600 {
      --tw-text-opacity: 1;
      color: rgb(71 85 105 / var(--tw-text-opacity));
}
.text-slate-800 {
      --tw-text-opacity: 1;
      color: rgb(30 41 59 / var(--tw-text-opacity));
}
.text-teal-300 {
      --tw-text-opacity: 1;
      color: rgb(94 234 212 / var(--tw-text-opacity));
}
.text-teal-400 {
      --tw-text-opacity: 1;
      color: rgb(45 212 191 / var(--tw-text-opacity));
}
.text-teal-600 {
      --tw-text-opacity: 1;
      color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-teal600 {
      --tw-text-opacity: 1;
      color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-violet-500 {
      --tw-text-opacity: 1;
      color: rgb(139 92 246 / var(--tw-text-opacity));
}
.text-violet-600 {
      --tw-text-opacity: 1;
      color: rgb(124 58 237 / var(--tw-text-opacity));
}
.text-white {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-600 {
      --tw-text-opacity: 1;
      color: rgb(202 138 4 / var(--tw-text-opacity));
}
.text-yellow-800 {
      --tw-text-opacity: 1;
      color: rgb(133 77 14 / var(--tw-text-opacity));
}
.underline {
      text-decoration-line: underline;
}
.no-underline {
      text-decoration-line: none;
}
.decoration-blue900 {
      text-decoration-color: #1e3a8a;
}
.decoration-2 {
      text-decoration-thickness: 2px;
}
.accent-blue800 {
      accent-color: #1e40af;
}
.opacity-0 {
      opacity: 0;
}
.opacity-100 {
      opacity: 1;
}
.opacity-50 {
      opacity: 0.5;
}
.opacity-70 {
      opacity: 0.7;
}
.opacity-80 {
      opacity: 0.8;
}
.opacity-90 {
      opacity: 0.9;
}
.shadow {
      --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-cardShadow {
      --tw-shadow: 0px 0px 80px rgba(59, 130, 246, 0.24);
      --tw-shadow-colored: 0px 0px 80px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-containerShadow {
      --tw-shadow: 0px 4px 12px rgba(30, 58, 138, 0.1);
      --tw-shadow-colored: 0px 4px 12px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-dropdownShadow {
      --tw-shadow: 0px 4px 16px 0px rgba(206, 212, 240, 0.80);
      --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
      --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
      --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-indigo-500\/50 {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}
.outline-none {
      outline: 2px solid transparent;
      outline-offset: 2px;
}
.outline {
      outline-style: solid;
}
.blur {
      --tw-blur: blur(8px);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
      --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-all {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-opacity {
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-shadow {
      transition-property: box-shadow;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-transform {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.duration-1000 {
      transition-duration: 1000ms;
}
.duration-150 {
      transition-duration: 150ms;
}
.duration-200 {
      transition-duration: 200ms;
}
.duration-300 {
      transition-duration: 300ms;
}
.duration-500 {
      transition-duration: 500ms;
}
.duration-700 {
      transition-duration: 700ms;
}
.ease-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.no-scrollbar::-webkit-scrollbar {
      display: none;
    }
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:not(.tox *) {
  font-family: var(--inter-font) !important;
}

.w-inherit {
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000000s ease-in-out 0s;
  transition: background-color 5000000s ease-in-out 0s;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #1e40af;
}

input[type='radio']:checked {
  border-color: #1e40af;
}

.transition-width {
  transition: width 0.3s ease-in-out;
}

/*
  * setting width of ck-editor as 100% since it's a common component and
  * width should be handled by wrapper div on top of this
*/
.ck.ck.ck-editor {
  width: 100%;
}

.ck.ck-editor__editable_inline {
  min-height: 150px !important;
  max-height: 300px;
  overflow-y: scroll;
}

.ck.ck-editor__editable_inline ul {
  list-style-position: inside;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  font-size: 0.8125rem;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #475569 !important;
}

/* Doc Editor css changes for CK Editor */
.doc-editor .tox.tox-tinymce .tox-edit-area {
  overflow-y: scroll;
  max-height: calc(100vh - 20.45rem) !important;
}

.doc-editor.doc-editor-modal .tox.tox-tinymce .tox-edit-area {
  height: calc(100vh - 20.45rem) !important;
}

.doc-editor .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap {
  background: transparent;
}

.doc-editor .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  margin: none;
}

.doc-editor .tox.tox-tinymce.tox-tinymce--disabled .tox-sidebar-wrap {
  background: gray;
}

.doc-editor .tox.tox-tinymce.tox-tinymce--disabled .tox-sidebar-wrap .tox-edit-area {
  background: gray;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-border-color {
  border-color: #cbd5e1;
}

/* Custom styles */

#sentry-feedback {
  --background: #ffffff;
  --border: 1px solid #1e3a8a;
  --foreground: #1e3a8a;
  --submit-background: #1e3a8a;
  --submit-background-hover: #1e40af;
  --input-outline-focus: none;
  .widget__actor {
    svg {
      color: #1e3a8a;
    }
  }
}

.placeholder\:text-gray-400::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-gray-400::placeholder {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-neutral-500::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(115 115 115 / var(--tw-text-opacity));
}

.placeholder\:text-neutral-500::placeholder {
      --tw-text-opacity: 1;
      color: rgb(115 115 115 / var(--tw-text-opacity));
}

.first\:mt-3:first-child {
      margin-top: 0.75rem;
}

.last\:flex:last-child {
      display: flex;
}

.hover\:scale-105:hover {
      --tw-scale-x: 1.05;
      --tw-scale-y: 1.05;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-not-allowed:hover {
      cursor: not-allowed;
}

.hover\:cursor-pointer:hover {
      cursor: pointer;
}

.hover\:rounded-full:hover {
      border-radius: 9999px;
}

.hover\:\!bg-blue-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-teal-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(153 246 228 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-teal-50:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-violet-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(221 214 254 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue800:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-neutral100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-orange-700:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.hover\:bg-slate-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_10\%\2c _\#6a3fda_65\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\]:hover {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 10%, #6a3fda 65%, rgba(30,58,138,1) 100%);
}

.hover\:shadow-buttonShadow:hover {
      --tw-shadow: 0px 4px 24px rgba(37, 99, 235, 0.24);
      --tw-shadow-colored: 0px 4px 24px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-indigo-500\/50:hover {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}

.focus\:border-blue800:focus {
      --tw-border-opacity: 1;
      border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.focus\:bg-blue100:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.focus\:bg-blue50:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.focus\:bg-blue800:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.focus\:bg-orange-700:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.focus\:bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_10\%\2c _\#6a3fda_65\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\]:focus {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 10%, #6a3fda 65%, rgba(30,58,138,1) 100%);
}

.focus\:shadow-lg:focus {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-indigo-500\/50:focus {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}

.focus\:outline-none:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
}

.focus\:ring-0:focus {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:bg-teal-50:active {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.disabled\:bg-gray-100:disabled {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.disabled\:text-gray-500:disabled {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}

.disabled\:text-grey500:disabled {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}

.disabled\:hover\:cursor-not-allowed:hover:disabled {
      cursor: not-allowed;
}

.disabled\:hover\:bg-gray-200:hover:disabled {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:block {
      display: block;
}

.group\/delete:hover .group-hover\/delete\:scale-110 {
      --tw-scale-x: 1.1;
      --tw-scale-y: 1.1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:top-1\/2 {
      top: 50%;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:top-1\/2 {
      top: 50%;
}

.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:top-2 {
      top: 0.5rem;
}

.peer:focus ~ .peer-focus\:-translate-y-8 {
      --tw-translate-y: -2rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:scale-75 {
      --tw-scale-x: .75;
      --tw-scale-y: .75;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
}

.peer:focus ~ .peer-focus\:font-semibold {
      font-weight: 600;
}

@media (min-width: 640px) {
      .sm\:w-1\/2 {
            width: 50%;
      }
      .sm\:w-1\/5 {
            width: 20%;
      }
      .sm\:w-2\/5 {
            width: 40%;
      }
      .sm\:w-\[50rem\] {
            width: 50rem;
      }
      .sm\:w-auto {
            width: auto;
      }
      .sm\:grid-cols-1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      .sm\:grid-rows-3 {
            grid-template-rows: repeat(3, minmax(0, 1fr));
      }
      .sm\:flex-nowrap {
            flex-wrap: nowrap;
      }
      .sm\:gap-12 {
            gap: 3rem;
      }
      .sm\:whitespace-nowrap {
            white-space: nowrap;
      }
}

@media (min-width: 768px) {
      .md\:w-1\/2 {
            width: 50%;
      }
      .md\:w-1\/3 {
            width: 33.333333%;
      }
      .md\:max-w-\[17rem\] {
            max-width: 17rem;
      }
      .md\:max-w-\[820px\] {
            max-width: 820px;
      }
      .md\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .md\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .md\:flex-nowrap {
            flex-wrap: nowrap;
      }
}

@media (min-width: 1024px) {
      .lg\:ml-auto {
            margin-left: auto;
      }
      .lg\:w-\[70rem\] {
            width: 70rem;
      }
      .lg\:max-w-5xl {
            max-width: 64rem;
      }
      .lg\:max-w-\[22rem\] {
            max-width: 22rem;
      }
      .lg\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .lg\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .lg\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .lg\:flex-row {
            flex-direction: row;
      }
}

@media (min-width: 1280px) {
      .xl\:w-\[10rem\] {
            width: 10rem;
      }
      .xl\:max-w-lg {
            max-width: 32rem;
      }
      .xl\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .xl\:grid-cols-5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
      }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}
.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d9825c';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_d9825c {font-family: '__Inter_d9825c', '__Inter_Fallback_d9825c';font-style: normal
}.__variable_d9825c {--inter-font: '__Inter_d9825c', '__Inter_Fallback_d9825c'
}

